import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import ProductLandingHero from "../../components/ProductLandingHero";
import BlockRenderer from "../../components/BlockRenderer";
import PopularProducts from "../../components/PopularProducts";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { AlgoliaInstantSearch } from "../../components/Algolia";
import { Translations } from "../../utils/algoliaProductsQuery";
import { ProductLinePageV2Context, ProductLinePageV2QueryProps } from "./types";
import "./styles.scss";
import Carousel from "../../components/carousel";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event11 } from "../../analytics/event11";
import ProductRatingsAndReviewsScript from "../../components/ProductRatingsAndReviewsScript";

export const data = graphql`
  query productLinePageV2($_id: String, $language: String, $category: String, $bestSeller: String, $market: String) {
    productLinePageV2: sanityProductLinePageV2(_id: { eq: $_id }) {
      ...ProductLinePageV2FieldsFull
    }
    productsBySubBrand: allSanityProductV2(
      filter: { subBrand: { name: { regex: $category } }, i18n_lang: { eq: $language }, market: { eq: $market } }
      sort: { order: DESC, fields: _id }
    ) {
      nodes {
        _id
        name
        smartProductId
        paSmartProductId
        paUPC
        subBrand {
          name
        }
        slug {
          current
        }
        images {
          alt
          ...Image
        }
        tags {
          tagName
        }
      }
    }
    productsByTagName: allSanityProductV2(
      filter: { tags: { elemMatch: { tagName: { regex: $bestSeller } } } }
      sort: { order: DESC, fields: _id }
    ) {
      nodes {
        _id
        name
        smartProductId
        paSmartProductId
        paUPC
        subBrand {
          name
        }
        slug {
          current
        }
        images {
          alt
          ...Image
        }
        tags {
          tagName
        }
      }
    }
    ...LocalizedContext
  }
`;

const ProductLinePageV2 = (props: PageProps<ProductLinePageV2QueryProps, ProductLinePageV2Context>) => {
  const { sanitySiteSettings } = useContext(LocalizedContext);

  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef(null);

  const { language = "" } = props.pageContext;
  const { htmlLang } = useSiteMetadata(language);
  const { productLinePageV2: productPage, sanityLabels = {} } = props.data;
  const { landingSections } = productPage;
  const { popularProductsBlockHeading = "", filterResultsCountLabel = "" } = sanityLabels.productLabels || {};

  const { length: productRangePage } = props.data["productsBySubBrand"].nodes;

  let filterType = "range";
  let { nodes: products = [] } = props.data["productsByTagName"];

  // CHECK IF PRODUCTS ARE FILTERED BY SUBBRAND
  if (products.length === 0) {
    products = props.data["productsBySubBrand"].nodes;
  }

  // DO NOT FILTER "BEST SELLING PRODUCTS" FOR RANGE PAGES
  if (!productRangePage) {
    products = products.filter(item =>
      item.tags?.find((tag: { tagName: string }) => tag.tagName === (Translations as any)[language].bestSeller)
    );
  }

  const genderCategoryPage =
    productPage.slug?.current === (Translations as any)[language].men ||
    productPage.slug?.current === (Translations as any)[language].women
      ? true
      : false;

  if (genderCategoryPage) {
    filterType = "gender";
  }
  const pageTitle =
    productPage.seo?.metaTitle || productPage.headline?.primaryText + " " + productPage.headline?.secondaryText;
  const pageDescription = productPage.seo?.metaDescription || productPage._rawIntroduction[0].children[0].text;

  // This is to make sure banner works of internationalisation
  const lineOne = productPage.headline?.primaryText + " ";
  const lineTwo = productPage.headline?.secondaryText === null ? " " : productPage.headline.secondaryText + " ";
  const lineThree = productPage.headline?.tertiaryText === null ? " " : productPage.headline.tertiaryText + " ";

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio === 0), {
        threshold: [0, 1],
        rootMargin: "-1px 0px 0px 0px"
      });

    cachedRef && observer.observe(cachedRef);

    // unmount
    return function () {
      cachedRef && observer.unobserve(cachedRef);
    };
  }, []);

  useEffect(() => {
    event11(products, sanitySiteSettings?.brandName, "Product Line Page");
  }, [products]);

  return (
    <Layout localizedContext={{ ...props.pageContext, ...props.data }} pageName={pageTitle} pageType={"products"}>
      <StructuredData
        type={"WebPage"}
        name={pageTitle}
        description={pageDescription}
        data={productPage._rawHeroImage}
        customData={productPage.seo.jsonld}
        language={htmlLang}
        slug={`/${productPage.heading}/ `}
      />
      <SEO
        title={pageTitle}
        description={pageDescription}
        imageUrl={productPage._rawHeroImage?.asset?.url}
        imageAlt={productPage._rawHeroImage?.alt}
      />
      <ProductRatingsAndReviewsScript />

      {productPage.showCarousel && productPage.plpCarousel && (
        <div className="carousel-block-plp">
          <Carousel {...productPage.plpCarousel} index={0} />
        </div>
      )}

      {!productPage.showCarousel && (
        <ProductLandingHero
          image={productPage._rawHeroImage}
          text={productPage._rawIntroduction}
          lineOne={lineOne}
          lineTwo={lineTwo}
          lineThree={lineThree}
        />
      )}

      <section className="rx-line--product">
        <div className={`rx-product--filters-stickyElement ${isSticky ? "isSticky" : null}`} ref={ref}></div>
        <section className="rx-product-listing">
          <AlgoliaInstantSearch
            source={"linepage"}
            data={props}
            isRangePage={filterType === "range" ? true : false}
            filtersQuery={`lang:${language} AND pageType:productV2 AND (${filterType}:${productPage.slug.current.replace(
              "-es",
              ""
            )} ${genderCategoryPage ? `OR gender: '${(Translations as any)[language].neutral}'` : ""})`}
            configureFiltersQuery={`lang:${language} AND pageType:productV2 AND (${filterType}: ${productPage.slug.current.replace(
              "-es",
              ""
            )} ${genderCategoryPage ? `OR gender: '${(Translations as any)[language].neutral}'` : ""})`}
            bestSellerQuery={`lang:${language} AND pageType:productV2 AND (${filterType}: ${productPage.slug.current.replace(
              "-es",
              ""
            )} ${
              genderCategoryPage
                ? `OR gender: '${(Translations as any)[language].neutral}') AND popularity:` +
                  (Translations as any)[language].bestSeller
                : ""
            }`}
            hitsPerPage={12}
          />

          {products && (
            <PopularProducts
              showTotalCount={productRangePage ? true : false}
              heading={productRangePage ? filterResultsCountLabel : popularProductsBlockHeading}
              products={products}
              language={language}
            />
          )}
        </section>
      </section>

      <section className="rx-line--teasers">
        {landingSections && landingSections.map(section => <BlockRenderer key={section._id} section={section} />)}
      </section>
    </Layout>
  );
};

export default ProductLinePageV2;
